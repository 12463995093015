@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap');

:root {
  --main-blue-color: #37ABE1;
  --main-background-color: #F3F5F7;

  --main-border-color: #E8ECEF;

  --main-danger-color: #D84C10;
  --main-success-color: #3FDD78;

  --regular-text-color: #222;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

body, html {
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: transparent;
	color: var(--regular-text-color);
}

input, button, textarea{
  font-family: 'Inter', sans-serif;
}

input::placeholder {
  font-family: 'Inter', sans-serif;
}

button {
	border: 0;
	outline: none;
	background: transparent;
	padding: 0;
	cursor: pointer;
}
