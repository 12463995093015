.WrapperChat {
    position: relative;
    width: 380px;
    height: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;

    @media screen and (max-width: 576px) {
        width: 95vw;
        height: 95vh;
    }
}


.WrapperMessages {
    flex-grow: 1;
    padding: 14px;
    overflow: auto;
}

.loader {
    width: 35px;
    aspect-ratio: 2.3;
    --_g: no-repeat radial-gradient(circle closest-side,#222 90%,#2220);
    background:
      var(--_g) 0%   50%,
      var(--_g) 50%  50%,
      var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}
