.ConfirmDialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(55, 55, 55, 0.95);
    z-index: 9999;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 10px 20px;
    line-height: 1.7;
    font-size: 16px;
    .WrapperActions {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        button {
            background: #fff;
            flex: 1 1 100%;
            height: 30px;
            border-radius: 20px;
            &.approve {
                background: #23c748;
            }
        }
    }
}
