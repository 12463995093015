.OpenChatButton {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--main-blue-color);
    box-shadow: 0 0 10px rgba(0, 0, 0, .3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        width: 30px;
        height: 30px;
    }

    .live {
        position: absolute;
        right: 6px;
        top: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #51d161;
        background-image: -webkit-linear-gradient(left top, #80dc7a, #23c748);
        background-image: linear-gradient(to right bottom, #80dc7a, #23c748);
    }
}
