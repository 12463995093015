.hidden,
.minimized {
    display: none;
}

.open {
    animation: slideFade 0.3s ease-out forwards;
}


@keyframes slideFade {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
