.WrapperPrompt {
    padding: 14px;
    position: absolute;
    bottom: 62px;
    left: 0;
    right: 0;
    z-index: 99;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;

    .PromptItem {
        width: calc(50% - 4px);
        padding: 8px;
        background: #fff;
        border: 1px solid #f1f3f5;
        border-radius: 12px;
        cursor: pointer;
        font-size: 12px;
        transition: all 0.3s;

        &:hover {
            background: #f1f3f5;
        }

        .title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: bold;
            width: 100%;
        }

        .text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            font-weight: normal;
            color: #bdbdbd;
        }
    }
}
