.MessageChat {
    position: relative;
    max-width: 100%;
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &.user {
        justify-content: flex-end;

        .text {
            background: var(--main-blue-color);
            color: #fff;
        }

        .icon {
            background: var(--main-blue-color);
            margin-left: 10px;
            margin-right: 0px;
            svg path {
                stroke: #fff;
            }
        }

        .time {

            right: 48px;
            left: initial;
        }
    }

    .icon {
        width: 37px;
        height: 37px;
        background: #f1f3f5;
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        svg {
            width: 20px;
            height: 20px;
        }
    }


    .text {
        display: inline-block;
        border-radius: 12px;
        padding: 10px;
        background: #f1f3f5;
        line-height: 1.2;
        word-break: break-word;
    }

    .time {
        position: absolute;
        bottom: 0;
        left: 48px;
        font-size: 8px;
        line-height: 1;
        opacity: 0.7;
    }
}

