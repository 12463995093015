.SendMessageChat {
    height: 60px;
    border-top: 1px solid var(--main-border-color);
    display: flex;
    flex-shrink: 0;
    position: relative;

    .notConnected {
        color: #bdbdbd;
        top: 19px;
        right: 39px;
        position: absolute;
    }
    input {
        border: 0;
        padding-left: 14px;
        outline: none;
        width: 100%;
    }
    button {
        padding-right: 14px;
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 17px;
            height: 17px;
        }
    }
    .buttonNotConnected {
        cursor: auto;
        opacity: .3;
    }
}
